<template>
    <div style="text-align: left;font-size: 12px;">
        <el-card>
            <h2 class="text-center m-b-50">转到余额</h2>
            <el-form :model="phoneloginForm" class="login_box_form" :rules="phonerules" ref="phoneloginForm" label-width="100px">
                <el-form-item prop="commission" label="提现金额">
                    <el-input v-model="phoneloginForm.commission" autocomplete="off" placeholder="￥请输入转出金额" style="width: 300px;">
                        <i slot="prefix" class="el-icon-user"></i>
                        <el-button type="text" style="padding-right:10px;color:#0366C3;" slot="suffix" @click="phoneloginForm.commission=UserCommission.userCommission">
                            全部
                        </el-button>
                    </el-input>
                    <span class="c-2 m-l-10">可转出金额<span style="color:rgb(3, 102, 195);">{{UserCommission.userCommission}}</span>元</span>
                </el-form-item>
                <el-form-item prop="code" label='验证码'>
                    <el-input v-model="phoneloginForm.code" autocomplete="off" placeholder="请输入短信验证码" style="width: 300px;">
                        <i slot="prefix" class="el-icon-unlock"></i>
                        <el-button @click="getLogincode" :disabled="!showGetCode" type="text" style="padding-right:10px;color:#0366C3;" slot="suffix">
                            <span v-if="showGetCode">获取验证码</span>
                            <span v-else class="count">{{count}} s</span>
                        </el-button>
                    </el-input><span v-if="loginUser && loginUser.userPhoneNumber" class="c-2 m-l-10">请输入手机号{{loginUser.userPhoneNumber.substring(0, 3) + "****" + loginUser.userPhoneNumber.substring(7, 11)}}收到的手机短信验证码！</span>
                </el-form-item>
                <el-form-item >
                    <div class="login_button">
                        <el-button @click="submitLoginPhone" type="primary">
                            提现
                        </el-button>
                        <el-button type="" @click="$oucy.back()">
                            取消
                        </el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { usercommission } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"



export default {
    name: "Photo",
    data() {

        return {
            count: 0,
            timer: null,
            showGetCode: true,

            phoneloginForm: {
                code: '',
                commission: '',
            },
            phonerules: {
                commission: [
                    { required: true, message: '请输入提现金额', trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ]
            },

            scanUserEntity: null,
            scanUserConfirm: false,
            showGetCode: true,
            UserCommission: {},
            loginUser: {},
        }
    },
    mounted() {
        this.loginUser = localGet(this.$oucy.userInfoKey)
        this.getUserCommission()
    },
    methods: {
        // 验证码倒计时
        getCodeCount() {
            this.count = 60;
            this.show = false;
            this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= 60) {
                    this.count--;
                } else {
                    this.showGetCode = true;
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
        getLogincode: function() {
            const me = this;
            usercommission.accSetupSendCode({
                uuid: oucy.getUUID()
            }).then(res => {
                this.showGetCode = false

                this.getCodeCount()
                me.$notify({
                    title: '短信发送成功',
                    message: '验证码已发送，请留意短信通知',
                    type: 'success'
                });
            });
        },
        submitLoginPhone: function() {
            const me = this;
            me.$refs["phoneloginForm"].validate((valid) => {
                if (valid) {
                    this.commissionToBlance()
                }
            });
        },
        getUserCommission() {
            usercommission.getUserCommission().then(res => {
                this.UserCommission = res
            })
        },
        commissionToBlance() {
            usercommission.commissionToBlance(this.phoneloginForm).then(res => {
                this.$message('提现成功')
                this.$oucy.replace('/acc/distribution/finish?commission='+this.phoneloginForm.commission)
            },err=>{                
            })
        },

    },

}
</script>
<style scoped lang="less">
.items {
    display: flex;
}

.item {
    padding: 4px 20px;
    line-height: 1.2;
    background: #FFFFFF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    position: relative;
    border: 1px solid #EAEAEA;

    &.select {
        border: 1px solid #2090FF;

        .corner {
            display: inline-block;
        }
    }
}

.corner {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
}

.categoryItem+.categoryItem {
    margin-left: 10px;
}
</style>